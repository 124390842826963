<section fxLayout="column" fxFlexAlign="stretch">
  <div id="main-content">
    <div class="row">
      <div class="col-9">
        <h2>2024 Holiday Season</h2>
        <p>
          Hi to All Players/moms and dads.  The volunteer helpers thank you for taking the time to help Santa.  Our players helped 
          fill 250 bags of toys, books, candy, and treats for handout by Santa and friends on Dec 7th in village of CP.
        </p>
        <img src="../../assets/images/home/sfx-football-santa-helpers.jpg" alt="SFX Football Santa Helpers">
        <h2>2024 season completed</h2>
        <p>
          Thank You to All players and parents
        </p>
        <h2>2025 season</h2>
        <p>
          USA Rookie football Grades 4th 5th 6th  watch for us
        </p>
        <h2>Summer camps</h2>
        <p>
          June 18th 2025 Former NFL Players Cecil Martin and Ryan Groy
        </p>
        <p>
          Additional camps  in June and July  by other NFL Players
        </p>
        <h2>League website</h2>
        <p>
          <a href="https://www.forwardfootballwi.com">https://www.forwardfootballwi.com</a>
        </p>
      </div>
      <div class="col-3">
        <figure class="figure">
          <img src="../../assets/images/home/rookie-football-2024-600.jpg" class="figure-img img-fluid rounded"
            alt="Rookie football 2024">
          <figcaption class="figure-caption">Rookie football 2024</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/Coach-and-Players-2024-600.jpg" class="figure-img img-fluid rounded"
            alt="Coach and Players">
          <figcaption class="figure-caption">Coach and Players</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/cecil-martin-home-600.jpg" class="figure-img img-fluid rounded"
            alt="Cecil Martin &amp; Ryan Groy Camp">
          <figcaption class="figure-caption">Cecil Martin &amp; Ryan Groy Camp</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/SFX-Game-home-600.jpg" class="figure-img img-fluid rounded"
            alt="SFX Football Game">
          <figcaption class="figure-caption">SFX Football Game</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/jim-leonhard-home-600.jpg" class="figure-img img-fluid rounded"
            alt="Jim Leonhard Camp">
          <figcaption class="figure-caption">Jim Leonhard Camp</figcaption>
        </figure>
      </div>
    </div>
    <div class="sponsors">
      <img src="../../assets/images/home/latitude.png" />
      <img src="../../assets/images/home/oldwi.png" />
      <img src="../../assets/images/home/sportsadvantEDGElogo.jpg" />
      <img src="../../assets/images/home/uwp.png" />
      <img src="../../assets/images/home/usa-football.png" />
  </div>
  </div>
</section>
